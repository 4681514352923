import React, { useCallback, useEffect, useRef } from 'react';
import _get from 'lodash/get';
import styled from 'styled-components';
import { config } from 'app/services/config';
import { DateTime } from 'luxon';
import { statusColor } from 'domain/helpers';
import { getLanguage } from 'app/services/i18n';

interface BarChartProps {
    height: string;
    items: {
        label: string;
        values: number[];
    }[];
}

const Wrapper = styled.div`
    display: flex;
    overflow: auto;
    
    > * {
        flex: 1;
    }
`;

const BarSect = styled.div`
    padding: 0 5px;
`;

const Bar = styled.div<{height: string}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: ${props => props.height};
`;

const BarPart = styled.div<{color: string, value: number}>`
    width: 10px;
    background-color: ${props => props.color};
    flex: 0 0 ${props => props.value}%;
`;

const BarLabel = styled.div`
    font-size: 9px;
    text-align: center;
    color: ${config.theme.colors.grey};
`;

const STATUSES = ['FAIL', 'READY', 'EXE'/*, 'SETUP', 'DISCONNECTED'*/, 'POWER_OFF'];

export function statusesToBarChart(history_statuses: any) {
    const dates = Object.keys(history_statuses);
    return dates.map(date => {
        const v = history_statuses[date];
        const barVals = STATUSES.reduce<number[]>((acc, s) => {
            return [...acc, parseInt(v[s] || 0)];
        }, []);
        const sum = barVals.reduce((acc, b) => acc+b, 0)
        const barPercent = barVals.map(v => v/sum*100).map(v => v || 0);
        const [lang] = getLanguage().split('-');
        const formats = { it: 'dd/MM', en: 'MM/dd' } as any;
        return {
            values: barPercent,
            label: DateTime.fromISO(date).toFormat( formats[lang] )
        }
    });
}

const BarChart = (props: BarChartProps) => {
    const { height, items } = props;
    const el = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (el && el.current) {
            setTimeout(() => {
                if (el.current) {
                    // @ts-ignore
                    el.current!.scrollLeft = 9999999;
                }
            }, 300);
        }
    }, [el.current]);
    
    return (
        <Wrapper ref={el}>
            {items.map(({label, values}, i) => {
                return (
                    <BarSect key={i}>
                        <Bar height={height} key={i}>
                            {values.map((value, index) => (
                                <BarPart key={index} color={statusColor(STATUSES[index])} value={value}  />
                            ))}
                        </Bar>
                        <BarLabel>{label}</BarLabel>
                    </BarSect>
                )
            })}
        </Wrapper>
    )
}

export default BarChart;