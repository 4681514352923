import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// Import Framework7
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';
// Import Framework7-React Plugin
import Framework7React from 'framework7-react';
// Import Framework7 Styles
import 'framework7/css/framework7.bundle.css';

import App from './App';
import { store } from './app/store';
import './app/services/i18n';
import * as serviceWorker from './serviceWorker';
import './domain/styles/icons.css';
import './domain/styles/index.css';

// Init F7 React Plugin
Framework7.use(Framework7React)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
