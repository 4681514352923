import React, { useEffect, useRef } from 'react';
import { Device } from 'framework7/framework7-lite.esm.bundle.js';
import { f7ready, theme } from 'framework7-react';
import {
  App as F7App,
} from 'framework7-react';
import cordovaApp from './app/services/cordova-app';
import routes from './domain/routes';
import Root from './domain/pages/Root';
import PushNotifications from 'app/services/push-notification';

function App() {
  useEffect(() => {
    f7ready((f7) => {
      PushNotifications.initCordovaNotifications();
      // Init cordova APIs (see cordova-app.js)
      if (Device.cordova) {
        cordovaApp.init(f7);
      }
    });
  });

  const f7params = useRef({
    id: 'com.pikkart.scm', // App bundle ID
    name: 'SCM', // App name
    theme: 'auto', // Automatic theme detection

    // App root data
    data: function () {
      return {};
    },

    // App routes
    routes: routes,
    // Input settings
    input: {
      scrollIntoViewOnFocus: Device.cordova && !Device.electron,
      scrollIntoViewCentered: Device.cordova && !Device.electron,
    },
    // Cordova Statusbar settings
    statusbar: {
      iosOverlaysWebView: true,
      androidOverlaysWebView: false,
    },
    navbar: {
      iosCenterTitle: true,
      mdCenterTitle: true,
    }
  });

  return (
    <F7App params={f7params.current}>
      <Root />
    </F7App>
  );
}

export default App;
