import React, { useEffect } from 'react';
import _get from 'lodash/get';
import styled from 'styled-components';
import { Card, CardContent, Row, Col, Gauge } from 'framework7-react';
import { IShift } from 'domain/types';
import { config } from 'app/services/config';
import { DateTime } from 'luxon';
import { getLanguage, useTranslation } from 'app/services/i18n';
import { KpiTag } from 'domain/pages/Machine';
import { capitalizeFirstLetter, kpiData, strKpiVal } from 'domain/helpers';

interface ShiftProps {
    item: IShift;
}

const Title = styled.div`
`;

const DataShift = styled.div`
    > strong {
        display: inline-block;
        padding-right: 1em;
    }
`;

const Data = styled.div`
    font-size: 16.56px;
    color: #002E6E;
    font-family: 'SF Pro Display', sans-serif;
    font-weight: normal;
    > label {
        display: block;
    }
`;

const Shift = (props: ShiftProps) => {
    const { t } = useTranslation();
    const [lang] = getLanguage().split('-');
    const { item } = props;
    const goalType = _get(item, 'Goal.Kpi');
    const objective = _get(item, 'Goal.Objective', 0);
    const current = _get(item, `Kpis.${goalType}`, 0);
    const kpi = kpiData(goalType);

    const goalVal = item.Goal ? current / objective : 0;
    const date = DateTime.fromISO(item.Date);
    const pts = [date.toFormat('d'), capitalizeFirstLetter(date.toFormat('MMMM')), date.toFormat('yyyy')];

    const InnerChart = styled.div`
    position: relative;
`;

    const Chart = styled.div`
    display: inline-block;
    position: relative;
    .gauge, svg {
        display: block;
    }
`;
    const _offset = 15;
    const RoundedChartText = styled.div`
    position: absolute;
    left: ${_offset}%;
    right: ${_offset}%;
    top: ${_offset}%;
    bottom: ${_offset}%;
`;

    return (
        <Card>
            <CardContent>
                <Row>
                    <Col width="70">
                        <Title className="shift-subtitle">{(lang === 'it' ? pts : [pts[1], pts[0], pts[2]]).join(' ')}</Title>
                        <DataShift>
                            <strong className="shift-title">{t('Machine.shift_nr')} {item.Number}</strong>
                        </DataShift>
                        {kpi &&
                            <Data>
                                <label className="goal-title">{t('Machine.goal')}</label>
                                {strKpiVal(current, objective, kpi)} <KpiTag>{kpi.tag}</KpiTag>
                            </Data>
                        }
                    </Col>
                    <Col width="30">

                        <Chart>
                            <InnerChart>
                                <Gauge
                                    type="circle"
                                    value={goalVal}
                                    size={150}
                                    borderColor={config.theme.colors.accent}
                                    borderWidth={13}
                                 />
                            </InnerChart>
                            <RoundedChartText>
                                <Gauge
                                    type="circle"
                                    value={goalVal}
                                    size={150}
                                    bgColor={config.theme.colors.gauge_grey}
                                    borderColor={config.theme.colors.gauge_grey}
                                    borderWidth={0}
                                    valueText={`${Math.round(goalVal * 100)}%`}
                                    valueFontSize={36}
                                    valueTextColor={config.theme.colors.gauge_text} >
                                </Gauge>
                            </RoundedChartText>
                        </Chart>
                    </Col>
                </Row>
            </CardContent>
        </Card>
    );
}

export default Shift;