import { config } from 'app/services/config';
import { IMachine } from 'domain/types';
import React from 'react';
import styled from 'styled-components';

interface PictureProps {
    item: IMachine;
    border?: string;
}

const Style = styled.figure<Partial<PictureProps>>`
    position: relative;
    margin: 0;
    border: 5px solid ${props => props.border};
    border-radius: 100%;

    &:before {
        content: "";
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
        padding-top: 100%;
    }

    &:after { /* to clear float */
        content: "";
        display: table;
        clear: both;
    }

    > img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 100%;
    }
`;

const Picture = (props: PictureProps) => (
    <Style border={props.border}>
        <img src={`${process.env.PUBLIC_URL}/assets/machines/${config.brand}/ico_${props.item.MachineFamily ? props.item.MachineFamily.toLowerCase() : 'nul'}.png`} />
    </Style>
)

export default Picture;