import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    f7,
    Button,
    Preloader,
    Icon,
    List,
    ListItem,
    Navbar,
    Page,
    Searchbar,
    Segmented,
    Subnavbar,
    SwipeoutActions,
    SwipeoutButton,
} from 'framework7-react';
import styled from 'styled-components';
import TimeAgo from 'timeago-react';
import { config } from 'app/services/config';
import { useTranslation } from 'app/services/i18n';
import { selectNotifications, getNotifications, resetNotifications, readNotification } from 'app/features/notifications';
import { selectCurrentUser } from 'app/features/auth';

interface StyledTextProps {
    unread: boolean;
}

const StyledText = styled.div<StyledTextProps>`
    color: ${props => props.unread ? config.theme.colors.default : config.theme.colors.default};
    font-weight: ${props => props.unread ? 'bold' : 'normal'};
`;

const StyledIcon = styled(Icon)`
    color: ${config.theme.colors.warning};
`;

const StyledCheck = styled(Icon)`
    font-size: 3rem;
`;

const SettingsNotifications = () => {
    const { t } = useTranslation();
    const [mode, setMode] = useState("UNREAD");
    const dispatch = useDispatch();
    const user = useSelector(selectCurrentUser);
    const items = useSelector(selectNotifications);

    useEffect(() => {
        dispatch(getNotifications({
            skip: 0,
            limit: 100
        }));
        dispatch(resetNotifications());
    }, [dispatch]);

    const filtered = useMemo(() => {
        if (mode === "ALL") return items;
        return items.filter(item => item.Notification.State !== "READ");
    }, [mode, items]);

    const read = useCallback((notification) => {
        dispatch(readNotification(notification));
    }, [dispatch]);

    return (
        <Page name="settings-notifications">
            <Navbar title={t(`Nav.settings_notifications_title`)} backLink>
                {/* <NavRight>
                    <Link searchbarEnable=".searchbar-notification" iconMaterial="search"></Link>
                </NavRight> */}
                <Subnavbar style={{ height: 'auto' }}>
                    <Segmented style={{ flexDirection: 'column' }}>
                        <Searchbar
                            style={{ marginBottom: '5px' }}
                            className="searchbar-notification"
                            placeholder={t(`SettingsNotifications.search_placeholder`)}
                            clearButton={true}
                            searchContainer=".search-list"
                            searchIn=".item-header, .item-text"
                            disableButtonText={t(`SettingsNotifications.search_cancel`)}
                        />
                        <Segmented raised style={{ margin: '0 50px 0 50px' }}>
                            <Button color="white" textColor="black" onClick={() => setMode("UNREAD")} active={mode === "UNREAD"} style={{ textTransform: 'capitalize' }}>{t(`SettingsNotifications.tab_unread`)}</Button>
                            <Button color="white" textColor="black" onClick={() => setMode("ALL")} active={mode === "ALL"} style={{ textTransform: 'capitalize' }}>{t(`SettingsNotifications.tab_all`)}</Button>
                        </Segmented>
                    </Segmented>
                </Subnavbar>
            </Navbar>
            <List className="searchbar-not-found">
                <ListItem title={t(`SettingsNotifications.not_found`)} />
            </List>
            <List style={{ textAlign: 'center', height: filtered.length === 0 ? 'inherit' : '0px' }}>
                {filtered.length === 0 ? <Preloader style={{ top: '30%' }} /> : ""}
            </List>
            <List style={{ marginTop: '50px' }} mediaList className="search-list searchbar-found">
                {filtered.map((item) => {
                    const { Id, Notification } = item;
                    const isUnread = Notification.State !== 'READ';
                    let notIcon = "";
                    switch (Notification.Category) {
                        case 'tool':
                            notIcon = './assets/notification_tool.png';
                            break;
                        case 'maintenance':
                            notIcon = './assets/notification_maintenance.png';
                            break;
                        case 'troubleshooting':
                            notIcon = './assets/notification_alarm.png';
                            break;
                        default:
                            break;
                    }
                    return (
                        <ListItem
                            key={Id}
                            swipeout
                            header={Notification.Title}>
                            <img src={notIcon} slot="media" height="47px" />
                            <span slot="footer">
                                <TimeAgo
                                    datetime={Notification.Timestamp}
                                    locale={user?.Language} />
                            </span>
                            <StyledText slot="text" unread={isUnread}>{Notification.Message}</StyledText>
                            {isUnread &&
                                <SwipeoutActions right>
                                    <SwipeoutButton color="green" close onClick={() => read(item)}><StyledCheck material="check" tooltip={t(`SettingsNotifications.action_read`)} /></SwipeoutButton>
                                </SwipeoutActions>
                            }
                        </ListItem>
                    );
                })}
            </List>
        </Page>
    )
}

export default SettingsNotifications;