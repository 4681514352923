import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
    f7,
    List,
    ListItem,
    Navbar,
    NavLeft,
    Page,
} from 'framework7-react';
import { useTranslation } from 'app/services/i18n';
import { getNotificationsCount, selectNotificationsCount } from 'app/features/notifications';
import { selectCurrentUser } from 'app/features/auth';

const Avatar = styled.div`
    position: relative;
    width: 36px;
    height: 36px;

    > img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%;
    }
`;

const Settings = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector(selectCurrentUser);
    const count = useSelector(selectNotificationsCount);

    useEffect(() => {
        dispatch(getNotificationsCount());
    }, [dispatch]);

    return (
        <Page name="settings" style={{ background: f7.device.ios ? "" : "#efeff4" }}>
            <Navbar>
                <NavLeft className="main-section-title">
                    {t(`Nav.settings_title`)}
                </NavLeft>
            </Navbar>
            <List style={{ margin: '0px' }}>
                <ListItem link="settings/profile" title={t('Settings.profile')} noChevron>
                    <Avatar slot="after"><img src={user && user.Image_Url ? user.Image_Url : `${process.env.PUBLIC_URL}/assets/user.svg`} /></Avatar>
                </ListItem>
                {/*<ListItem link="settings/settings" title={t('Settings.settings')} />*/}
                <ListItem link="settings/notifications" title={t('Settings.notifications')} badge={`${count || 0}`} badgeColor="orange" />
            </List>
        </Page>
    )
}

export default Settings;