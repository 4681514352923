import React from 'react';
import {
    f7,
    Block,
    Button,
    Navbar,
    Page,
    NavLeft,
    NavTitle,
} from 'framework7-react';
import { useTranslation } from 'app/services/i18n';
import { config } from 'app/services/config';
import { TelegramBot } from 'app/services/telegram-bot';

const Maintenance = () => {
    const { t } = useTranslation();

    const openTelegramUrl = () => {
        const telegramBotUrl = TelegramBot.getTelegramBotUrl();
        if (telegramBotUrl) {
            window.open(telegramBotUrl, '_blank', 'noopener,noreferrer')
        } else {
            showToastBottom(t(`Maintenance.toast_no_url_to_open`));
        }
    }

    const showToastBottom = (text: any) => {
        const toastBottom = f7.toast.create({
            text: text,
            closeTimeout: 2000,
        });
        toastBottom.open();
    };

    return (
        <Page name="maintenance">
            <Navbar className="main-section-title">
                <NavTitle style={{ fontWeight: 'bold' }} className="main-section-title">
                    {t(`Nav.maintenance_title`)}
                </NavTitle>
            </Navbar>
            <Block strong inset style={{ textAlign: 'center' }}>
                <p>{t(`Maintenance.text`)}</p>
                <Button large raised fill style={{ margin: '0px 50px' }} onClick={() => { openTelegramUrl() }}>{t(`Maintenance.btn_add`)}</Button>
                {/*<br />
                <Button raised round>{t(`Maintenance.btn_install_telegram`)}</Button>*/}
            </Block>
        </Page>
    )
}

export default Maintenance;