import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Block,
    BlockFooter,
    Button,
    List,
    ListButton,
    LoginScreen, LoginScreenTitle, Page, View
} from 'framework7-react';
import styled from 'styled-components';
import { logIn, selectAuthenticated } from 'app/features/auth';
import { config } from 'app/services/config';

const LogoWrapper = styled.div`
    padding: 2rem;

    > img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
    }
`;

const ProfileWrapper = styled.div`
    text-align: center;

    > img {
        max-width: 64px;
    }
`;

const ButtonWrapper = styled.div`
    .button {
        text-transform: none;
    }
`;

function Login() {
    const isLogged = useSelector(selectAuthenticated);
    const dispatch = useDispatch();
    const submit = useCallback((loginButton) => {
        dispatch(logIn(loginButton));
    }, [dispatch]);

    return (
        <LoginScreen id="login-screen" opened={true}>
            <View>
                <Page loginScreen style={{ textAlign: "center" }}>
                    <LogoWrapper>
                        <img src={config.brand === 'scm' ? 'assets/login-scm.svg' : 'assets/login-cms.svg'} alt="SCM Group" />
                    </LogoWrapper>
                    <ProfileWrapper>
                        <img src={'assets/user.png'} alt="SCM Group" />
                    </ProfileWrapper>
                    <ButtonWrapper>
                        <Block>
                            {config.brand === 'scm' && <><Button large raised fill onClick={() => submit("scm_group")}>myMachines login</Button><p>* use your My SCM credentials</p></>}
                            {config.brand === 'cms' && <><Button large raised fill onClick={() => submit("cms")} style={{ marginTop: "20px" }}>myMachines login</Button><p>* use your My CMS credentials</p></>}
                        </Block>
                    </ButtonWrapper>
                </Page>
            </View>
        </LoginScreen>
    );
}

export default Login;