import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next, useTranslation as _useTranslation } from 'react-i18next';
import * as timeago from 'timeago.js';
import translations from 'domain/locales.json';
import { config } from './config';

i18n
// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
.use(LanguageDetector)
// pass the i18n instance to react-i18next.
.use(initReactI18next)
// init i18next
// for all options read: https://www.i18next.com/overview/configuration-options
.init({
    resources: translations,
    lng: 'en',
    fallbackLng: 'en',
    //debug: true,

    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
});

config.locales.forEach(locale => {
    timeago.register(locale.code, locale.timeago);
});

export const useTranslation = () => {
    const { t: _t } = _useTranslation();
    return {
        t: (key: string) => _t(key) as string
    };
}

export const getLanguage = (): string => {
    return i18n.language ||
        (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
        'en';
};

export default i18n;