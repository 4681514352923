import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
    Block,
    Button,
    Icon,
    List,
    ListInput,
    ListItem,
    Navbar,
    NavLeft,
    Page,
    PageContent,
} from 'framework7-react';
import { selectCurrentUser, changeCurrentLocale, logOut } from 'app/features/auth';
import { config } from 'app/services/config';
import { useTranslation } from 'app/services/i18n';

const SettingsProfile = () => {
    const { t } = useTranslation();
    const user = useSelector(selectCurrentUser);
    const dispatch = useDispatch();

    const changeLang = useCallback((e, v) => {
        dispatch(changeCurrentLocale(e.target.value));
    }, [dispatch]);

    const logout = useCallback(() => {
        dispatch(logOut());
    }, [dispatch]);

    const Avatar = styled.div`
    position: relative;
    width: 36px;
    height: 36px;

    > img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%;
    }
`;

    return (
        <Page name="settings-profile">
            <Navbar title={t(`Nav.settings_profile_title`)} backLink className="side-section-title" />
            {user && (<>
                <List noHairlinesMd className="settings-list" style={{ marginTop: '0px', marginBottom: '15px' }}>
                    <ListItem divider={false}>
                        <Avatar>
                            <img src={user && user.Image_Url ? user.Image_Url : `${process.env.PUBLIC_URL}/assets/user.svg`} />
                        </Avatar>
                    </ListItem>
                    <ListInput
                        label={t('SettingsProfile.firstname')}
                        type="text"
                        value={user.Firstname}
                        readonly={true}
                    />
                    <ListInput
                        label={t('SettingsProfile.lastname')}
                        type="text"
                        value={user.Lastname}
                        readonly={true}
                    />
                    <ListInput
                        label={t('SettingsProfile.email')}
                        type="text"
                        value={user.Email}
                        readonly={true}
                    />
                </List>
                <List noHairlinesMd className="settings-list" style={{ marginTop: '0px' }}>
                    {/*
                <ListInput
                    label={t('SettingsProfile.languages') }
                    type="select"
                    value={user.Language}
                    onChange={changeLang}
                >
                    {config.locales.map(locale => (
                    <option key={locale.code} value={locale.code}>{locale.label}</option>
                    ))}
                </ListInput>
                */}
                    <ListInput
                        label={t('SettingsProfile.roles')}
                        type="textarea"
                        value={user.Role_Ids?.map(r => r.Name).join(', ')}
                        readonly={true}
                    />
                    <ListInput
                        label={t('SettingsProfile.groups')}
                        type="textarea"
                        value={user.Machine_Group_Ids?.map(r => r.Name).join(', ')}
                        readonly={true}
                    />
                </List>
            </>)}
            <Block>
                <Button color="#026CFF" large raised fill onClick={logout} style={{ margin: '0px 65px' }}>{t(`SettingsProfile.btn_logout`)}</Button>
            </Block>
        </Page>
    )
}

export default SettingsProfile;