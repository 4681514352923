import React from 'react';
import {
    Navbar,
    Page,
} from 'framework7-react';
import { useTranslation } from 'app/services/i18n';

const SettingsSettings = () => {
    const { t } = useTranslation();
    
    return (
        <Page name="settings-settings">
            <Navbar title={t(`Nav.settings_settings_title`)} backLink>
            </Navbar>
            @todo
        </Page>
    )
}

export default SettingsSettings;