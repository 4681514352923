import React, { useEffect, useMemo } from 'react';
import _get from 'lodash/get';
import { Col, Preloader, Row } from 'framework7-react';
import styled from 'styled-components';
import { IMachine, IMachineDetails } from 'domain/types';
import { getLanguage, useTranslation } from 'app/services/i18n';
import BarChart, { statusesToBarChart } from './BarChart';
import Picture from './Picture';
import SemiGauge from './SemiGauge';
import { getInstantVars, instantData, statusColor, strInstantVal } from 'domain/helpers';
import { RenderShift } from 'domain/pages/Machine';
import DoubleGauge from './DoubleGauge';

interface MachineProps {
    item: IMachine;
    details?: IMachineDetails;
    timeline: boolean;
    disconnected: boolean;
}

const Wrapper = styled.div`
     margin-top: 5px;
`;

const Shift = styled.div`
    margin: auto 0 18px auto;
    font-size: 3px;
    // margin-bottom: 1em;
    > strong {
        display: block;
    }
`;

const Operator = styled.div`
    // font-size: 12px;
`;

const Goal = styled.div`
    margin: 10px 0 auto 0;
    font-size: 12px;
    color: #002E6E;
    font-family: 'SF Pro Display', sans-serif;
    font-weight: normal;
    > label {
        display: block;
    }
`;

const StyledTimeline = styled(Row)`
    // --f7-grid-row-gap: 1em;
    --f7-grid-gap: 16px;
    align-items: center;
`;


const Machine = (props: MachineProps) => {
    const { t } = useTranslation();
    const lang = getLanguage();
    const { item, details, timeline, disconnected } = props;
    const shift = _get(details, 'shift');
    const status = _get(details, 'status');
    const history = _get(details, 'history');
    let operator = _get(details, 'operator.value');
    if (operator === 'null') operator = '-';

    const instants = getInstantVars({ ...item, ...details });
    const history_statuses = _get(history, 'statuses', {});
    const kpi = shift ? RenderShift(shift) : null;

    const isDisconnected = disconnected;

    const barChartItems = useMemo(() => {
        return statusesToBarChart(history_statuses);
    }, [lang, history_statuses]);

    const isLoading = /*!barChartItems.length ||*/ !details;

    return (
        <Wrapper>
            <Row>
                <Col className="side-col" width="28">
                    <Picture border={statusColor(_get(status, 'Status'))} item={item} />
                </Col>
                {!isDisconnected && (<>
                    <Col className="center-col" width="40">
                        <div>
                            {shift && operator && (
                                <Shift>
                                    {shift && (<strong className="shift-title">{t('Machine.shift_nr')} {_get(shift, 'Number')}</strong>)}
                                    {operator && (<Operator className="shift-subtitle">{operator}</Operator>)}
                                </Shift>
                            )}
                            {kpi && (
                                <Goal>
                                    <label className="goal-title">{t('Machine.goal')}</label>
                                    {kpi[0]}
                                </Goal>
                            )}
                        </div>
                    </Col>
                    <Col className="side-col" width="28">
                        {kpi && (<>
                            {kpi[1]}
                        </>)}
                        {!kpi && (<>
                            <DoubleGauge inner={0} outer={0} />
                        </>)}
                    </Col>
                </>)}
            </Row>
            {!isDisconnected && timeline && (
                <StyledTimeline>
                    {isLoading && <Preloader />}
                    {!isLoading && <>
                        {Object.keys(instants).map((field) => {
                            const value = instants[field];
                            const data = instantData(field);
                            return <Col width="20">
                                <SemiGauge
                                    value={value}
                                    strValue={strInstantVal(field, value)}
                                    label={`${t(`Machine.${field}`).toUpperCase()}<br />(${data.unit})`}
                                    needle={data.noNeedle !== true}
                                    max={data.max}
                                />
                            </Col>;
                        })}
                        <Col width="60">
                            {barChartItems &&
                                <BarChart
                                    height='50px'
                                    items={barChartItems}
                                />
                            }
                        </Col>
                    </>}
                </StyledTimeline>
            )}
        </Wrapper>
    )
}

export default Machine;

