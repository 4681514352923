import { IJWT, IUser } from 'domain/types';
import i18n from './i18n';
import { fakeLogin, realLogin, loadUserByToken, changeUserSettings, renewToken } from './loader';

const CONSTANTS = {
    localStorageKey: 'jwt'
}

function setToken(jwt: IJWT) {
    const packed = JSON.stringify(jwt);
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem(CONSTANTS.localStorageKey, packed);
}

export function getToken() {
    const jwt = localStorage.getItem(CONSTANTS.localStorageKey);
    return jwt ? JSON.parse(jwt) : false;
}

export function hasToken() {
    return !!getToken();
}

export function withToken() {
    const lang = i18n.language;
    return {
        headers: {
            Authorization: `Bearer ${getToken().accessToken}`,
            'accept-language': lang
        }
    }
}

export async function checkToken(): Promise<IUser> {
    try {
        const res = await loadUserByToken();
// hijacking language selection into english
res.Language = 'en-US';
//
        return res;
    } catch (err) {
        try {
            const jwt = await renewToken();
            setToken(jwt);
            const res = await loadUserByToken();
            return res;
        } catch (err) {
            throw err;
        }
    }
}

export async function login(): Promise<string> {
    try {
        // @todo receive from external service
        const res = await fakeLogin();
        setToken(res);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function requestLogin(accessToken: string): Promise<string> {
    try {
        // @todo receive from external service
        const res = await realLogin(accessToken);
        setToken(res);
        return res;
    } catch (err) {
        throw err;
    }
}

export async function logout(): Promise<boolean> {
    // remove user from local storage to log user out
    localStorage.removeItem(CONSTANTS.localStorageKey);
    return true;
}

export async function changeLocale(locale: string): Promise<IUser> {
    try {
        const res = await changeUserSettings({
            Language: locale
        });
        return res;
    } catch (err) {
        throw err;
    }
}