import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';
import { INotification } from 'domain/types';
import { loadNotifications, loadNotificationsCount, resetNotifications as loaderResetNotifications, readNotification as loaderReadNotification } from 'app/services/loader';

interface NotificationsState {
    loading: boolean;
    error?: string;
    notifications: INotification[];
    notificationsCount: number;
}

const initialState: NotificationsState = {
    loading: false,
    notifications: [],
    notificationsCount: 0
};

export const notificationsReducer = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        loading: (state) => {
            state.loading = true;
        },
        gotNotifications: (state, action: PayloadAction<INotification[]>) => {
            state.notifications = action.payload;
            state.loading = false;
        },
        gotNotificationsCount: (state, action: PayloadAction<number>) => {
            state.notificationsCount = action.payload;
            state.loading = false;
        },
        gotFailure: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.loading = false;
        },
        notificationIsRead: (state, action: PayloadAction<INotification>) => {
            state.notifications = state.notifications.map(n => action.payload.Id === n.Id ? {
                Id: n.Id,
                Notification: { ...n.Notification, State: "READ" }
            } : n);
        },
    },
});

export const { loading, gotNotifications, gotNotificationsCount, gotFailure, notificationIsRead } = notificationsReducer.actions;

interface GetNotificationsParams {
    skip?: number;
    limit?: number;
}

export const getNotifications = (params: GetNotificationsParams): AppThunk => dispatch => {
    dispatch(loading());

    loadNotifications(params)
    .then((data) => {
        dispatch(gotNotifications(data));
    }, (error) => {
        dispatch(gotFailure(error.toString()));
    });
}

export const getNotificationsCount = (): AppThunk => dispatch => {
    console.log('get notifications count')
    dispatch(loading());

    loadNotificationsCount()
    .then((data) => {
        dispatch(gotNotificationsCount(data));
    }, (error) => {
        dispatch(gotFailure(error.toString()));
    });
}

export const resetNotifications = (): AppThunk => dispatch => {
    dispatch(loading());

    loaderResetNotifications()
    .then((data) => {
    }, (error) => {
        dispatch(gotFailure(error.toString()));
    });
}

export const readNotification = (notification: INotification): AppThunk => dispatch => {
    loaderReadNotification(notification)
    .then((data) => {
        dispatch(notificationIsRead(notification));
    }, (error) => {
        dispatch(notificationIsRead(notification));
        dispatch(gotFailure(error.toString()));
    });
}

export const selectNotifications = (state: RootState) => state.notifications.notifications;
export const selectNotificationsCount = (state: RootState) => state.notifications.notificationsCount;

export default notificationsReducer.reducer