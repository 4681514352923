import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { LoginScreen, Block, Page, View, Button } from 'framework7-react';
import { getNotificationsCount } from 'app/features/notifications';
import { useTranslation } from 'app/services/i18n';

const Text = styled.div`
    font-size: 2em;
`;

const ButtonWrapper = styled.div`
    text-align: center;

    .button {
        max-width: 150px;
        margin: 0 auto;
        text-transform: none;
    }
`;

function Offline() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const retry = useCallback(() => {
        dispatch(getNotificationsCount());
    }, [dispatch]);

    return (
        <LoginScreen id="offline-screen" opened={true}>
            <View>
                <Page loginScreen style={{ textAlign: "center" }}>
                    <Text>{t('Offline.text')}</Text>

                    <ButtonWrapper>
                        <Block>
                            <Button large raised fill onClick={() => retry()}>{t('Offline.button')}</Button>
                        </Block>
                    </ButtonWrapper>
                </Page>
            </View>
        </LoginScreen>
    );
}

export default Offline;