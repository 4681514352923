import { config } from 'app/services/config';
import { Gauge } from 'framework7-react';
import React from 'react';
import styled from 'styled-components';

interface DoubleGaugeProps {
    inner: number;
    outer: number;
}

const Chart = styled.div`
    display: inline-block;
    position: relative;
    .gauge, svg {
        display: block;
    }
`;

const OuterChart = styled.div<{ inverted: boolean }>`
    position: relative;
    ${props => props.inverted ? `transform: rotateY(180deg);` : null}
`;

const _offset = 13;
const InnerChart = styled.div`
    position: absolute;
    left: ${_offset}%;
    right: ${_offset}%;
    top: ${_offset}%;
    bottom: ${_offset}%;
`;

const _subOffset = 26;
const RoundedChartText = styled.div`
    position: absolute;
    left: ${_subOffset}%;
    right: ${_subOffset}%;
    top: ${_subOffset}%;
    bottom: ${_subOffset}%;
`;

const DoubleGauge = ({ inner, outer }: DoubleGaugeProps) => (
    <Chart>
        <OuterChart inverted={outer < 0}>
            <Gauge
                type="circle"
                value={Math.abs(outer) / 2}
                size={200}
                borderColor={config.theme.colors[outer > 0 ? 'outer_circle_success' : 'outer_circle_danger']} 
                borderWidth={13}
                // borderColor={config.theme.colors['outer_circle_success']}
            />
        </OuterChart>
        <InnerChart>
            <Gauge
                type="circle"
                value={inner}
                size={138}
                borderColor={config.theme.colors.inner_circle}
                borderWidth={13}
            />
        </InnerChart>
        <RoundedChartText>
            <Gauge
                type="circle"
                value={inner}
                size={138}
                bgColor={config.theme.colors.gauge_grey}
                borderColor={config.theme.colors.gauge_grey}
                borderWidth={0}
                valueText={`${Math.round(inner * 100)}%`}
                valueFontSize={36}
                valueTextColor={config.theme.colors.gauge_text} />
        </RoundedChartText>
    </Chart>
);

export default DoubleGauge;