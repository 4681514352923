import { f7 } from 'framework7-react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Settings as DateSettings } from 'luxon';
import { AppThunk, RootState } from 'app/store';
import { IUser } from 'domain/types';
import * as authService from 'app/services/auth';
import i18n from 'app/services/i18n';
import { config, contextVar } from 'app/services/config';

interface AuthState {
    authenticated: boolean;
    authenticating: boolean;
    device_offline: boolean;
    device_offline_timer: number | undefined;
    token?: string;
    user?: Partial<IUser>;
    logged_at?: number; // unix timestamp in seconds
    error?: string;
}

const initialState: AuthState = {
    authenticated: false,
    authenticating: false,
    device_offline: false,
    device_offline_timer: undefined,
};

export const authReducer = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loggingIn: (state) => {
            state.authenticated = false;
            state.authenticating = true;
        },
        loggedIn: (state, action: PayloadAction<IUser>) => {
            state.authenticated = true;
            state.logged_at = Math.floor(Date.now() / 1000);
            state.user = action.payload;
        },
        deviceOffline: (state, action: PayloadAction<boolean>) => {
            state.device_offline = action.payload;
        },
        deviceOfflineTimer: (state, action: PayloadAction<"online" | "offline">) => {
            if (state.device_offline_timer === undefined && action.payload === 'offline') {
                state.device_offline_timer = new Date().getTime();
            }
            if (action.payload === 'online') {
                state.device_offline_timer = undefined;
            }

        },
        loggedOut: state => {
            state.authenticated = false
            state.authenticating = false
            delete state.user;
        },
        logInFailure: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
            state.authenticating = false;
            state.authenticated = false;
            delete state.user;
        },
    },
});

export const { loggingIn, loggedIn, deviceOffline, deviceOfflineTimer, loggedOut, logInFailure } = authReducer.actions;

export const checkToken = (): AppThunk => dispatch => {
    dispatch(loggingIn());

    authService.checkToken()
        .then((user) => {
            i18n.changeLanguage(user.Language);
            DateSettings.defaultLocale = user.Language;
            dispatch(loggedIn(user));
        }, (error) => {
            authService.logout();
            dispatch(logInFailure(error.toString()));
        });
}

declare global {
    interface Window {
        cordova: any;
        SFAuthSession: any;
    }
}

export const logIn = (loginRedirect: string): AppThunk => dispatch => {
    dispatch(loggingIn());

    if (config.fake_login) {
        authService.login()
            .then(
                () => {
                    authService.checkToken().then((user) => {
                        i18n.changeLanguage(user.Language);
                        DateSettings.defaultLocale = user.Language;
                        dispatch(loggedIn(user));
                    });
                },
                (error: Error) => {
                    dispatch(logInFailure(error.toString()));
                }
            );
    } else {
        let url1 = contextVar('LOGIN');
        url1 += contextVar('LOGIN_REDIRECT_URI');


        if (f7.device.ios) {
            if (window.cordova && window.cordova.version) {
                if (window.cordova.plugins && window.SFAuthSession) {
                    window.SFAuthSession.start("", url1,
                        function (data: any) {
                            console.log('auth success', data)
                        },
                        function (error: any) {
                            console.log('auth error', error)
                        })
                }
                else {
                    console.log('plugin SFAuthSession not found')
                }
            }
        }
        else {
            //console.log("we are NOT in cordova");
            const newWindow = window.open(url1, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null;
        }
    }
};

export const requestLogin = (accessToken: string): AppThunk => dispatch => {
    dispatch(loggingIn());

    authService.requestLogin(accessToken)
        .then(
            () => {
                authService.checkToken().then((user) => {
                    dispatch(loggedIn(user));
                });
            },
            (error: Error) => {
                authService.logout();
                dispatch(logInFailure(error.toString()));
            }
        );
}

export const getTokens = (): AppThunk => dispatch => {
    console.log('getting tokens')
}

export const logOut = (): AppThunk => dispatch => {
    const url = `${contextVar('LOGOUT')}${contextVar('LOGOUT_REDIRECT_URI')}`


    if (f7.device.ios) {
        if (window.cordova && window.cordova.version) {
            if (window.cordova.plugins && window.SFAuthSession) {
                window.SFAuthSession.start("", url,
                    function (data: any) {
                        console.log('logout success', data)

                        authService.logout()
                            .then(
                                (jwt) => {
                                    dispatch(loggedOut());
                                },
                                (error: Error) => {
                                    dispatch(logInFailure(error.toString()));
                                }
                            );
                    },
                    function (error: any) {
                        console.log('logout error', error)
                    })
            }
            else {
                console.log('plugin SFAuthSession not found')
            }
        }
    }
    else {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null;

        authService.logout()
            .then(
                (jwt) => {
                    dispatch(loggedOut());
                },
                (error: Error) => {
                    dispatch(logInFailure(error.toString()));
                }
            );
    }
};

export const changeCurrentLocale = (locale: string): AppThunk => (dispatch, getState) => {
    authService.changeLocale(locale)
        .then((data) => {
            i18n.changeLanguage(locale);
            DateSettings.defaultLocale = locale;

            dispatch(loggedIn({
                ...getState().auth.user,
                Language: locale
            } as IUser));
        }, (error) => {
            dispatch(logInFailure(error.toString()));
        });
};

export const registerCheckingConnection = () => {
    //solo in ambiente cordova
    if (window.cordova && window.cordova.version) {

        const onOffline = () => {
            console.log('on offline');
            deviceOffline(true)
        }

        const onOnline = () => {
            console.log('on online');
            deviceOffline(false)
        }

        document.addEventListener("offline", onOffline, false);
        document.addEventListener("online", onOnline, false);
    }
}

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectAuthenticated = (state: RootState) => state.auth.authenticated;
export const selectAuthenticating = (state: RootState) => state.auth.authenticating;
export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectOffline = (state: RootState) => state.auth.device_offline;
export const selectReallyOffline = (state: RootState) => {
    if (state.auth.device_offline_timer && new Date().getTime() - state.auth.device_offline_timer > config.offline) {
        return true;
    }
    return false;
}

export default authReducer.reducer