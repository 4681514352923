import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from 'app/store';
import { IMachine, IMachineDetails, SEGMENTS, IMachineComplete } from 'domain/types';
import { GetMachineDetailsParams, loadMachineDetails, loadMachines } from 'app/services/loader';

type LoadingTypes = "index" | "details";

interface StateMachineDetails {
    [key: string]: IMachineDetails;
}

interface MachinesState {
    loading: {
        [key: string]: boolean;
    };
    end: boolean;
    activeMachineGroup?: string;
    error?: string;
    machines: IMachine[];
    machineDetails: StateMachineDetails;
}

interface MachineLoadingError {
    msg: string;
    type: LoadingTypes;
}

const initialState: MachinesState = {
    loading: {
        index: false,
        details: false
    },
    end: false,
    machines: [],
    machineDetails: {},
};

export const machinesReducer = createSlice({
    name: 'machines',
    initialState,
    reducers: {
        loading: (state, action: PayloadAction<string>) => {
            const type = action.payload;
            state.loading[type] = true;
        },
        setActiveMachineGroup: (state, action: PayloadAction<string>) => {
            state.activeMachineGroup = action.payload;
            state.end = false;
            state.machines = [];
            state.machineDetails = {};
        },
        setLoadingStop: (state, action: PayloadAction<boolean>) => {
            state.end = action.payload;
            state.loading.index = false;
        },
        resetMachines: (state) => {
            state.machines = [];
            state.machineDetails = {};
        },
        gotMachines: (state, action: PayloadAction<IMachine[]>) => {
            state.machines = [...state.machines, ...action.payload];
            state.loading.index = false;
        },
        gotMachineDetails: (state, action: PayloadAction<IMachineDetails>) => {
            const toUpdate = action.payload;
            const toMerge = {
                [toUpdate.Id]: toUpdate
            };
            state.machineDetails = {
                ...state.machineDetails,
                ...toMerge
            };
            state.loading.details = false;
        },
        gotFailure: (state, action: PayloadAction<MachineLoadingError>) => {
            const {msg, type} = action.payload;
            state.error = msg;
            state.loading[type] = false;
        }
    },
});

export const { loading, setActiveMachineGroup, setLoadingStop, resetMachines, gotMachines, gotMachineDetails, gotFailure } = machinesReducer.actions;

interface GetMachinesParams {
    group: string;
    skip?: number;
    limit?: number;
    reset?: boolean;
}

export const getMachines = (params: GetMachinesParams): AppThunk => dispatch => {
    dispatch(loading("index"));
    if (params.reset) {
        dispatch(setLoadingStop(false));
        dispatch(resetMachines());
    }

    loadMachines(params)
    .then((data) => {
        dispatch((data.length === 0) ? setLoadingStop(true) : gotMachines(data));
    }, (error) => {
        dispatch(gotFailure({
            type: "index",
            msg: error.toString()
        }));
    });
}

export const getMachineDetails = (params: GetMachineDetailsParams): AppThunk => dispatch => {
    dispatch(loading("details"));
    loadMachineDetails(params)
    .then((data) => {
        dispatch(gotMachineDetails(data));
    }, (error) => {
        dispatch(gotFailure({
            type: "details",
            msg: error.toString()
        }));
    });
}

export const selectLoading = (state: RootState) => state.machines.loading;
export const selectEnd = (state: RootState) => state.machines.end;
export const selectActiveMachineGroup = (state: RootState) => state.machines.activeMachineGroup;
export const selectMachines = (state: RootState) => state.machines.machines;
export const selectMachineGroups = (state: RootState) => state.auth.user?.Machine_Group_Ids;
export const selectMachinesDetails = (state: RootState) => state.machines.machineDetails;
export const selectMachineDetails = (state: RootState, id: string) => {
    return {
        ...(state.machines.machines.find(m => m.Id === id)),
        ...(state.machines.machineDetails[id] || {})
    } as IMachineComplete;
};

export default machinesReducer.reducer