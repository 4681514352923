import { config } from 'app/services/config';
import { Gauge } from 'framework7-react';
import React from 'react';
import styled from 'styled-components';

interface SemiGaugeProps {
    value: number;
    strValue?: string;
    label: string;
    needle?: boolean;
    max?: number;
}

const Style = styled.div`
    display: inline-block;
    max-width: 100%;
`;

const Chart = styled.div`
    display: inline-block;
    position: relative;

    &:before {
        content: "";
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
        padding-top: 100%;
    }

    &:after { /* to clear float */
        content: "";
        display: table;
        clear: both;
    }

    > svg {
        max-width: 100%;
    }
`;

const Value = styled.div`
    position: absolute;
    font-family: 'Work Sans', sans-serif;
    font-size: 14.9px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 100%;
    z-index: 20;
    height: 100%;
    width: 100%;
    left: 0;
    top: 50%;
    text-align: center;
    margin-top: -8px;
`;

const Label = styled.div`
    position: relative;
    margin-top: -5px;
    text-align: center;
    font-family: 'Work Sans', sans-serif;
    font-size: 8.94px;
    color: #707070;
`;

const SemiGauge = ({ value, strValue, label, needle, max = 100 }: SemiGaugeProps) => (
    <Style>
        <Chart>
            <svg version="1.1" className="semigauge-cursor" xmlns="http://www.w3.org/2000/svg" width="75" height="75" viewBox="0 0 75 75">
                {needle && (<>
                    <path fill="#F0F0F0" d="M 10.755473379608674 59.941322423181305 A 34.9125 34.9125 0 1 1 64.26695456681209 59.914566680358064 L 63.98519715031932 59.6786238731964 A 34.545 34.545 0 1 0 11.036994712454899 59.7050979766215 Z" className="highcharts-pane " stroke="#ccc" stroke-width="3"></path>
                    <path fill="#026CFF" d="M 0 -15 L 1.0473750000000002 -15 L 34.9125 -0.5 L 34.9125 0.5 L 1.0473750000000002 15 L 0 15 Z" transform={`translate(37.5,37.5) rotate(${(225 + 45) * value / max - 225})`} data-z-index="1" className="highcharts-dial"></path>
                </>)}
                <circle cx="0" cy="0" r={needle ? "22" : "35"} data-z-index="2" className="highcharts-pivot" transform="translate(37.5,37.5)" stroke="#fff" stroke-width="1" fill="#026CFF"></circle>
            </svg>
            <Value>{strValue || value}</Value>
        </Chart>
        <Label dangerouslySetInnerHTML={{ __html: label }} />
    </Style>
);

export default SemiGauge;