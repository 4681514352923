import _get from 'lodash/get';
import { IMachineComplete } from "./types";

const mm2m = (mm: number) => mm / 1000;

const hhmmss = (sec: number) => {
    const hours = Math.floor(sec / 3600);
    sec %= 3600;
    const minutes = Math.floor(sec / 60);
    const seconds = sec % 60;
    let pts = [];
    if (hours) pts.push(`${Math.round(hours)}h`);
    if (minutes) pts.push(`${Math.round(minutes)}m`);
    if (seconds) pts.push(`${Math.round(seconds)}s`);
    return pts.length ? pts.join(':') : '0h';
}

const roundTwo = (val: number) => {
    const res = parseFloat(`${val}`).toFixed(2);
    const [int, dec] = `${res}`.split('.');
    if (dec === '00') return int;
    return res;
}

export function kpiData(val: string) {
    switch (val) {
        case 'LINEAR_FOOTAGE': return { tag: 'lf', unit: 'm', transform: (current: number, objective: number) => `${roundTwo(mm2m(current))}/${mm2m(objective)} m` };
        case 'SPINDLE_TIME': return { tag: 'st', unit: 'sec', transform: (current: number, objective: number) => `${hhmmss(current)}/${hhmmss(objective)}` };
        case 'PART_COUNT': return { tag: 'pc', unit: 'pz' };
        case 'WOODCUT_VOLUME': return { tag: 'wv', unit: 'm³' };
        case 'APPLIED_EDGE_LENGTH': return { tag: 'ael', unit: 'm', transform: (current: number, objective: number) => `${roundTwo(mm2m(current))}/${mm2m(objective)} m` };
        case 'CYCLE_COUNT': return { tag: 'cc', unit: 'sides' };
    }
}

export function strKpiVal(current: number, objective: number, kpi: any) {
    if (kpi.transform) {
        return kpi.transform(current, objective);
    }
    return `${roundTwo(current)}/${objective} ${kpi.unit}`;
}

export function statusColor(status: string) {
    switch (status) {
        case 'FAIL': return '#FC5C65';
        case 'READY': return '#FDC40C';
        case 'EXE': return '#2FD36F';
        case 'SETUP': return '#FDC40C';
        case 'DISCONNECTED': return '#000';
        case 'POWER_OFF': return '#8A8A8A';
        default: return '#000';
    }
}

const MMIN_CONVERSION = 16.6666666667;

export function instantData(field: string) {
    switch (field) {
        case 'rapid':
        case 'feed':
            return { unit: '%' }
        case 'blade_carriage_feed':
            return { unit: 'm/min', max: 170 * MMIN_CONVERSION }
        case 'track_speed':
            return { unit: 'm/min', noNeedle: true }
        case 'blade_speed_rate':
            return { unit: 'rpm', max: 4300 }
        case 'gap':
            return { unit: 'mm', noNeedle: true }
        case 'production_rate':
            return { unit: 'pieces/min', noNeedle: true }
    }
    return {};
}

export function strInstantVal(field: string, value: number) {
    switch (field) {
        case 'track_speed':
        case 'blade_carriage_feed':
            return `${+(Math.round(value / MMIN_CONVERSION)).toFixed(2)}`;
    }
    return `${Math.round(value)}`;
}

export function getInstantVars(item: Partial<IMachineComplete>): any {
    switch (item?.MachineFamily?.toUpperCase()) {
        case 'AWJ':
        case 'CDL':
        case 'BOR':
            return {
                feed: _get(item, 'cncproc_feed[0].value', 0),
                rapid: _get(item, 'cncproc_rapid[0].value', 0),
            }
        case 'SEZ':
            return {
                blade_carriage_feed: _get(item, 'sub_feed[0].value', 0),
                blade_speed_rate: _get(item, 'sub_speed[0].value', 0)
            }
        case 'EBA':
            return {
                track_speed: _get(item, 'sub_feed[0].value', 0),
                gap: _get(item, 'gap[0].value', 0)
            }
        case 'TFO':
            return {
                production_rate: _get(item, 'prod_rate[0].value', 0),
            }
    }
    return {};
}

export function getGoalProgressVelocity(shift: any) {
    /*let shift = {
      "Number": 2,
      "StartTime": "10:20:00",
      "EndTime": "12:30:00",
      "Goal": {
        "Kpi": "LINEAR_FOOTAGE",
        "Objective": 10
      },
      "Progress": {
        "Kpi": "LINEAR_FOOTAGE",
        "Current": 0
      }
    }*/
    try {
        let today_date = new Date().toISOString().substring(0, 10);
        let diff = Math.abs(+new Date(`${today_date} ${shift.EndTime}`) - +new Date(`${today_date} ${shift.StartTime}`));
        let ShiftLength = Math.floor((diff / 1000) / 60);
        let today_now_time = new Date().toLocaleTimeString('en-US', { hour12: false, hour: "numeric", minute: "numeric" })
        diff = Math.abs(+new Date(`${today_date} ${today_now_time}`) - +new Date(`${today_date} ${shift.StartTime}`));
        let ShiftElapsed = Math.floor((diff / 1000) / 60);
        let ExpectedValue = !!shift.Goal ? (shift.Goal.Objective * ShiftElapsed) / ShiftLength : 0
        if (ExpectedValue > 0) {
            let PI = Math.PI
            let velocity = (shift.Progress.Current - ExpectedValue) / ExpectedValue * (1 + 10 * (ShiftElapsed / ShiftLength))
            let ProgressVelocity = Math.round(Math.atan(velocity) * (180 / PI)) // radiant to degree
            // Return degree
            return ProgressVelocity
        } else {
            return 0
        }
    } catch (e) {
        console.error(e)
        return 0
    }
}

//capitalize only the first letter of the string. 
export function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

//capitalize all words of a string. 
export function capitalizeWords(str: string) {
    return str.replace(/(?:^|\s)\S/g, function (a) { return a.toUpperCase(); });
};