
import Dashboard from './pages/Dashboard';
import Machine from './pages/Machine';
import NotFoundPage from './pages/404';
import Settings from './pages/Settings';
import SettingsProfile from './pages/Settings/Profile';
import SettingsNotifications from './pages/Settings/Notifications';
import Maintenance from './pages/Maintenance';
import SettingsSettings from './pages/Settings/Settings';

var routes = [
  {
    path: '/',
    component: Dashboard,
  },
  {
    path: '/machines/:id/',
    component: Machine,
  },
  {
    path: '/maintenance',
    component: Maintenance,
  },
  {
    path: '/settings/',
    component: Settings,
    routes: [
      {
        path: '/settings/profile',
        component: SettingsProfile,
      },
      {
        path: '/settings/settings',
        component: SettingsSettings,
      },
      {
        path: '/settings/notifications',
        component: SettingsNotifications,
      },
    ]
  },
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
