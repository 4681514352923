import timeago_en from 'timeago.js/lib/lang/en_US';
import timeago_it from 'timeago.js/lib/lang/it';

export const config = {
    env: (process.env.REACT_APP_ENV_SCRIPT || process.env.REACT_APP_ENV) || 'tst',
    brand: (process.env.REACT_APP_BRAND_SCRIPT || process.env.REACT_APP_BRAND)  || 'scm',
    dev: ((process.env.NODE_ENV || "development") === "development"),
    bot: process.env.REACT_APP_BOT_URL || "",
    mock: process.env.REACT_APP_API_MOCK || false,
    fake_login: process.env.REACT_APP_FAKE_LOGIN || false,
    bot_enabled: process.env.REACT_APP_ENABLE_TELEGRAM_BOT || false,
    polling: process.env.REACT_APP_NOTIFICATIONS_POLLING || 10000,
    offline: process.env.REACT_APP_OFFLINE_DELAY || 5000,
    locales: [
        { code: 'it-IT', lang: 'it', label: 'Italiano', timeago: timeago_it },
        { code: 'en-US', lang: 'en', label: 'English', timeago: timeago_en }
    ],
    theme: {
        colors: {
            default: '#000',
            accent: '#2196f3',
            strong: '#6c7b8c',
            success: '#2bac1f',
            warning: '#fdc209',
            danger: '#ff0000',
            gauge_grey: '#E7E7E7',
            grey: '#222',
            gauge_text: '#707070',
            inner_circle: '#026CFF',
            outer_circle_success: '#26DE81',
            outer_circle_danger: '#ff0000',
        }
    }
};

export function contextVar( name: string ): string {
    const noBrand = ['api'];
    const key =  noBrand.includes(name)
    ? `REACT_APP_${config.env.toUpperCase()}_${name.toUpperCase()}`
    : `REACT_APP_${config.env.toUpperCase()}_${config.brand.toUpperCase()}_${name.toUpperCase()}`;
    
    return process.env[key] || '';
}