import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { View, Page, Link, List, ListItem, BlockTitle, Button, Sheet, Popup, Navbar, NavRight, NavLeft } from 'framework7-react';
import { useDispatch, useSelector } from "react-redux";
import { selectActiveMachineGroup, selectMachineGroups, setActiveMachineGroup } from 'app/features/machines';
import { useTranslation } from 'app/services/i18n';

const GroupsView = styled.section`
    .item-title {
        word-break: break-all;
    }
`;

function Groups() {
    const active = useSelector(selectActiveMachineGroup);
    const [selected, setSelected] = useState(active);
    const { t } = useTranslation();
    const items = useSelector(selectMachineGroups);

    const dispatch = useDispatch();

    useEffect(() => {
        setSelected(active);
    }, [active]);

    const toggle = useCallback((group) => {
        setSelected(group.Id);
    }, []);

    const submit = useCallback((e) => {
        if (selected && selected !== active) {
            dispatch(setActiveMachineGroup(selected));
        }
    }, [dispatch, selected, active]);

    return (
        <Popup
            backdrop
            className="demo-popup">
            <Page>
                <div style={{ display: 'flex', flexFlow: 'column', height: '100%', background: 'white' }}>
                    <Button onClick={submit} popupClose style={{ textAlign: 'left', paddingLeft: '16px', textTransform: 'capitalize' }}>
                        {t('Groups.submit')}
                    </Button>

                    <BlockTitle className="group-title" medium style={{ marginTop: 'auto' }}>{t('Groups.title')}</BlockTitle>
                    <List mediaList style={{ margin: 0 }}>
                        {items?.map(item => (
                            <ListItem
                                key={item.Id}
                                checkbox
                                title={item.Name}
                                onChange={() => toggle(item)}
                                name="sel"
                                checked={item.Id === selected}
                                className="item-multiline"
                            ></ListItem>
                        ))}
                    </List>

                    <div style={{ flexGrow: 1, background: 'white' }} />
                </div>
            </Page>
        </Popup>
    );
}

export default Groups;