export class PushNotifications {
    static setFirebaseToken = (token) => {
        localStorage.setItem('firebaseToken', token);
    };

    static getFirebaseToken = () => {
        return localStorage.getItem('firebaseToken');
    };

    static initCordovaNotifications = (onMessageEventHandler) => {
        console.log("initCordovaNotifications");
        if (window.hasOwnProperty("cordova")) {

            const firebase = window.cordova.plugins.firebase;
            if (firebase && firebase.messaging) {
                // [START refresh_token]
                // Callback fired if Instance ID token is updated.
                window.cordova.plugins.firebase.messaging.requestPermission({ forceShow: true }).then(function () {
                    console.log("You'll get foreground notifications when a push message arrives");
                });

                window.cordova.plugins.firebase.messaging.getToken().then(function (token) {
                    console.log("Got device token: ", token);
                    PushNotifications.setFirebaseToken(token);
                });

                window.cordova.plugins.firebase.messaging.onTokenRefresh(function () {
                    console.log("Device token updated");
                    window.cordova.plugins.firebase.messaging.getToken().then(function (token) {
                        console.log("Got device token: ", token);
                        PushNotifications.setFirebaseToken(token);
                    });
                });
                // [END refresh_token]
                window.cordova.plugins.firebase.messaging.onMessage(function (payload) {
                    console.log("New foreground FCM message: ", payload);
                    onMessageEventHandler(payload);
                });

                window.cordova.plugins.firebase.messaging.onBackgroundMessage(function (payload) {
                    console.log("New background FCM message: ", payload);
                    onMessageEventHandler(payload);
                });
            }
        }
    }
}
export default PushNotifications;