import axios from 'axios';
import { Dispatch } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { config, contextVar } from './config';
import { getToken, withToken, logout } from './auth';
import i18n from './i18n';
import { IUser, IMachine, SEGMENTS, IMachineDetails, INotification, IJWT, withResult } from 'domain/types';
import { PushNotifications } from './push-notification';
import { loggedOut, deviceOffline, deviceOfflineTimer } from 'app/features/auth';

export function initLoader(dispatch: Dispatch<any>) {
    // Add a response interceptor
    axios.interceptors.response.use(function (response) {
        dispatch(deviceOffline(false));
        dispatch(deviceOfflineTimer('online'));
        return response;
    }, function (error) {
        const res = error.response;
        if (!res) {
            //TODO: quando viene eseguita la richiesta delle notifiche in localhost fallisce
            dispatch(deviceOffline(true));
            dispatch(deviceOfflineTimer('offline'));
        }
        if ([401, 403].includes(res.status) === true) {
            logout();
            dispatch(loggedOut());
        }

        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error);
    });
}

function withLang(headers: { [key: string]: string }) {
    const lang = i18n.language;
    return {
        ...headers,
        'accept-language': lang === 'en' ? 'en-US' : 'it-IT'
    };
}

export async function fakeLogin(): Promise<any> {
    try {
        const res = await axios.request<{ result: IMachine[] }>({
            url: `${contextVar('api')}fake/auth/login`,
            method: 'post',
            data: {
                Email: "maurizio.tomei@scmgroup.com",
                Password: "Password123."
            },
            headers: {
                'content-type': `application/vnd.maestroconnect.email-login+json`
            }
        });
        return res.data.result;
    } catch (err) {
        throw err;
    }
}
export async function realLogin(accessToken: string): Promise<any> {
    try {
        const res = await axios.request<{ result: IMachine[] }>({
            url: `${contextVar('api')}external-auth/login`,
            method: 'post',
            data: {
                accessToken: accessToken,
            },
            headers: withLang({
                'content-type': `application/vnd.maestroconnect.email-login+json`
            })
        });
        return res.data.result;
    } catch (err) {
        throw err;
    }
}

export async function loadUserByToken(): Promise<IUser> {
    try {
        const res = await axios.get<{ result: IUser }>(`${contextVar('api') + getToken().user.OrganizationCode}/users/me`, withToken());
        return res.data.result;
    } catch (err) {
        throw err;
    }
}

export async function renewToken(): Promise<IJWT> {
    try {
        const res = await axios.post<{ result: IJWT }>(`${contextVar('api')}external-auth/refresh`, {
            token: getToken().refreshToken
        });
        return res.data.result;
    } catch (err) {
        throw err;
    }
}

interface GetMachinesParams {
    group: string;
    skip?: number;
    limit?: number;
}

export async function loadMachines(params: GetMachinesParams): Promise<IMachine[]> {
    try {
        const { group, skip = 0, limit } = params;
        const res = await axios.request<{ result: IMachine[] }>({
            url: `${contextVar('api') + getToken().user.OrganizationCode}/machinegroups/${group}/machines`,
            method: 'get',
            ...withToken(),
            params: {
                skip,
                limit,
            },
        });
        return res.data.result;
    } catch (err) {
        throw err;
    }
}

export interface GetMachineDetailsParams {
    id: string;
    segments: SEGMENTS[];
    args?: {
        history: {
            from: DateTime;
            to: DateTime;
        }
    }
}

const toDateTime = (dt?: DateTime) => dt ? `${dt.toFormat('yyyy-MM-dd')}T${dt.toFormat('TT')}.000Z` : null;
const toDate = (dt?: DateTime) => dt ? `${dt.toFormat('yyyy-MM-dd')}` : null;

export async function loadMachineDetails(params: GetMachineDetailsParams): Promise<IMachineDetails> {
    const { args } = params;
    const res = await params.segments.reduce<Promise<Partial<IMachineDetails>>>(async (acc, segment) => {
        let endpoint, mock, onlyOne;
        const message_qs = `?limit=1`;
        switch (segment) {
            case "shift":
                endpoint = `${contextVar('api')}v2/machines/${params.id}/shifts/current?progress=true`;
                break;
            case "shift_lasts":
                endpoint = `${contextVar('api')}v2/machines/${params.id}/shifts/history?limit=6&skip=0&from=${toDate(args?.history.from.plus({ day: -1 }))}&to=${toDate(args?.history.to)}`;
                mock = require('../../mock/shift-history.json').result;
                break;
            case "history":
                // 2020-08-20T04%3A00%3A00.880Z
                endpoint = `${contextVar('api')}machines/${params.id}/status/historical-aggregated?from=${toDateTime(args?.history.from)}&to=${toDateTime(args?.history.to)}&aggregatedBy=days`;
                mock = require('../../mock/status-historical.json').result;
                break;
            case "status":
                endpoint = `${contextVar('api')}machines/${params.id}/status/last`;
                break;
            case "cncproc_rapid":
                endpoint = `${contextVar('api')}machines/${params.id}/messages/${encodeURIComponent('Machine/Cnc/CncProcesses/01/RapidOverride')}${message_qs}`;
                break;
            case "cncproc_feed":
                endpoint = `${contextVar('api')}machines/${params.id}/messages/${encodeURIComponent('Machine/Cnc/CncProcesses/01/FeedOverride')}${message_qs}`;
                break;
            case "cncproc_rate":
                endpoint = `${contextVar('api')}machines/${params.id}/messages/${encodeURIComponent('Machine/Cnc/CncProcesses/01/FeedRate')}${message_qs}`;
                break;
            case "sub_feed":
                endpoint = `${contextVar('api')}machines/${params.id}/messages/${encodeURIComponent('Machine/OperatingGroups/01/Subgroups/01/FeedRate')}${message_qs}`;
                break;
            case "sub_speed":
                endpoint = `${contextVar('api')}machines/${params.id}/messages/${encodeURIComponent('Machine/OperatingGroups/01/Subgroups/01/SpeedOverride')}${message_qs}`;
                break;
            case "gap":
                endpoint = `${contextVar('api')}machines/${params.id}/messages/${encodeURIComponent('Machine/ProductionProcesses/01/Gap')}${message_qs}`;
                break;
            case "program":
                endpoint = `${contextVar('api')}machines/${params.id}/messages/${encodeURIComponent('Machine/ProductionProcesses/01/Programs/01/Name')}${message_qs}`;
                break;
            case "program_status":
                endpoint = `${contextVar('api')}machines/${params.id}/messages/${encodeURIComponent('Machine/ProductionProcesses/01/Programs/01/Status')}${message_qs}`;
                break;
            case "operator":
                endpoint = `${contextVar('api')}machines/${params.id}/messages/${encodeURIComponent('Machine/Hmi/User')}${message_qs}`;
                onlyOne = true;
                mock = require('../../mock/operator.json').result[0];
                break;
            case "prod_rate":
                endpoint = `${contextVar('api')}machines/${params.id}/messages/${encodeURIComponent('Machine/ProductionProcesses/01/Programs/01/ProductionRate')}${message_qs}`;
                break;
        }
        try {
            if (config.mock && mock) {
                return {
                    ...(await acc),
                    [segment]: mock
                };
            }
            const res = await axios.request<any>({
                url: endpoint,
                method: 'get',
                ...withToken(),
            });
            return {
                ...(await acc),
                [segment]: onlyOne ? res.data.result[0] : res.data.result
            };
        } catch (err) {
            return acc;
        }
    }, Promise.resolve({}));

    return {
        Id: params.id,
        ...res
    };
}

interface NotificationsCountResponse {
    result: {
        NEW: number;
        NOT_READ: number;
        READ: number;
    }
}

export async function loadNotificationsCount(): Promise<number> {
    try {
        if (config.mock) {
            return require('../../mock/get-count-notifications.json').result.NEW;
        }
        const res = await axios.request<NotificationsCountResponse>({
            url: `${contextVar('api')}users/me/notifications/count`,
            method: 'get',
            ...withToken(),
        });
        return res.data.result.NEW;
    } catch (err) {
        throw err;
    }
}

interface GetNotificationsParams {
    skip?: number;
    limit?: number;
}

export async function loadNotifications(params: GetNotificationsParams): Promise<any> {
    try {
        if (config.mock) {
            return require('../../mock/get-notifications-list.json').result;
        }
        const res = await axios.request<withResult<INotification>>({
            url: `${contextVar('api')}users/me/notifications`,
            method: 'get',
            ...withToken(),
            params
        });
        return res.data.result;
    } catch (err) {
        throw err;
    }
}

export async function resetNotifications(): Promise<any> {
    try {
        const res = await axios.request<INotification>({
            url: `${contextVar('api')}users/me/notifications/old`,
            method: 'patch',
            ...withToken(),
        });
        return res.data;
    } catch (err) {
        throw err;
    }
}

export async function readNotification(notification: INotification): Promise<any> {
    try {
        const res = await axios.request<INotification>({
            url: `${contextVar('api')}users/me/notifications/${notification.Id}/read`,
            method: 'patch',
            ...withToken(),
        });
        return res.data;
    } catch (err) {
        throw err;
    }
}

interface ChangeUserSettingsParams {
    Language: string;
}

export async function changeUserSettings(params: ChangeUserSettingsParams): Promise<IUser> {
    try {
        const res = await axios.request<IUser>({
            url: `${contextVar('api') + getToken().user.OrganizationCode}/users/me`,
            method: 'put',
            ...withToken(),
            data: params
        });
        return res.data;
    } catch (err) {
        throw err;
    }
}

export function checkPushNotifications() {
    if (PushNotifications.getFirebaseToken() != null) {
        console.log("sending FIREBASE TOKEN to server");
        sendTokenToServer(PushNotifications.getFirebaseToken())
            .then(function () {
                console.log("FIREBASE TOKEN to server: success " + PushNotifications.getFirebaseToken());
            })
            .catch(function () {
                console.log("FIREBASE TOKEN to server: error");
            });
    } else {
        console.log("no FIREBASE TOKEN to send");
    }
}

export async function sendTokenToServer(token: any) {
    try {
        const res = await axios.request({
            url: `${contextVar('api')}users/me/subscriptions/push`,
            method: 'post',
            ...withToken(),
            data: {
                regToken: token,
                app: "uwa"
            },
        });
        return res.data.result;
    } catch (err) {
        throw err;
    }
};

export async function getTelegramBotData() {
    try {
        const res = await axios.request({
            url: `${contextVar('api')}SCM/auth/telegram-bot`,
            method: 'post',
            ...withToken(),
            data: {
            },
        });
        return res.data.result;
    } catch (err) {
        throw err;
    }
};