import React, { useEffect, useRef, useState } from 'react';
import { Views, Toolbar, Link, View, f7, f7ready, Icon, Badge } from 'framework7-react';
import { useDispatch, useSelector } from "react-redux";
import styled from 'styled-components';
import { checkToken, selectAuthenticated, selectCurrentUser, selectOffline, requestLogin, selectAuthenticating, selectReallyOffline, registerCheckingConnection } from "app/features/auth";
import { getToken, hasToken } from 'app/services/auth';
import { useTranslation } from 'app/services/i18n';
import Groups from './Groups';
import Login from './Login';
import Offline from './Offline';
import { selectActiveMachineGroup, setActiveMachineGroup } from 'app/features/machines';
import { getNotificationsCount, selectNotificationsCount } from 'app/features/notifications';
import { config } from 'app/services/config';
import { checkPushNotifications, getTelegramBotData } from 'app/services/loader';
import { initLoader } from 'app/services/loader';
import { TelegramBot } from 'app/services/telegram-bot';

type PHASES = "goto_login" | "trying" | "ready" | "offline";

export default () => {
    const [phase, setPhase] = useState<PHASES>();
    const [loginAttempt, setLoginAttempt] = useState<string | undefined>();
    const { t } = useTranslation();
    const isLogged = useSelector(selectAuthenticated);
    const isLogging = useSelector(selectAuthenticating);
    const isOffline = useSelector(selectOffline);
    const isReallyOffline = useSelector(selectReallyOffline);
    const user = useSelector(selectCurrentUser);
    const group = useSelector(selectActiveMachineGroup);
    const notifications = useSelector(selectNotificationsCount);
    const dispatch = useDispatch();
    const timer = useRef<number>()

    useEffect(() => {
        initLoader(dispatch);
    }, [dispatch]);

    // trying to continue session
    useEffect(() => {
        const $checkToken = async () => {
            const search = window.location.search;
            const params = new URLSearchParams(search);
            const jwt = params.get('jwt');
            const refreshToken = params.get('refresh_token');
            /*
            @restore this block change URL and removes jwt data (we suspect presence makes chaos)
            if (jwt && jwt.length) {
                // eslint-disable-next-line no-restricted-globals
                history.pushState({}, document.title, '/');
            }
            */

            if (isOffline) {
                //if (isReallyOffline) {
                    setPhase("offline");
                /*} else {
                    setPhase("trying");
                }*/
                return;
            }

            if (isLogged) {
                setPhase("ready");
            } else {
                if (hasToken()) {
                    const jwt = getToken();
                    if (jwt) {
                        dispatch(checkToken());
                        setPhase("trying");
                    }
                } else if (loginAttempt !== jwt) {
                    // se sono presenti come query params jwt e refreshToken eseguo la chiamata per i token delle api
                    if (jwt && refreshToken) {
                        dispatch(requestLogin(jwt));
                    }
                    else {
                        setPhase("goto_login");
                    }
                } else {
                    setPhase("goto_login");
                }
            }
            if (jwt) {
                setLoginAttempt(jwt);
            }
        };

        $checkToken();
    }, [dispatch, isOffline, isReallyOffline, isLogging, isLogged, loginAttempt]);

    // when logged in, start pulling push notifications and get the telegram bot url
    useEffect(() => {
        if (isLogged) {
            registerCheckingConnection();
            checkPushNotifications();
            if (config.bot_enabled) {
                getTelegramBotData()
                    .then(function (botData) {
                        if (botData && botData.location) {
                            const botUrl = botData.location;

                            TelegramBot.setTelegramBotUrl(botUrl);
                        }
                    })
                    .catch(function () {
                        console.log('no telegram bot url');
                    });
            }
        }
    }, [isLogged]);

    // useEffect(() => {
    //     if (isLogged) {
    //         if (!timer.current) {
    //             timer.current = window.setInterval(() => {
    //                 dispatch(getNotificationsCount());
    //             }, config.polling);
    //         }
    //     } else {
    //         if (timer.current) {
    //             window.clearInterval(timer.current);
    //         }
    //     }
    // }, [isLogged]);

    // when I've got user, immediatly set activeGroup to first (All)
    useEffect(() => {
        if (!group && user && user.Machine_Group_Ids && user.Machine_Group_Ids.length) {
            dispatch(setActiveMachineGroup(user.Machine_Group_Ids[0].Id));
        }
    }, [dispatch, user, group]);

    useEffect(() => {
        const loading = () => {
            if (phase === "trying") {
                f7.preloader.show();
            } else {
                f7.preloader.hide();
            }
        }
        loading();
        f7ready(loading);
    }, [phase]);

    if (phase === "offline") {
        return <Offline />;
    }

    if (phase === "goto_login") {
        f7ready(() => {
            if (f7.view.main) {
                f7.view.main.history = ['/']; // url of the home pagec
                f7.view.main.router.history = ['/'];
            }
        });
        return <Login />;
    }

    const maintenanceTab = config.bot_enabled ? <Link tabLink="#view-maintenance" iconF7="wrench" text={t(`Nav.maintenance`)} /> : "";
    const maintenanceView = config.bot_enabled ? <View id="view-maintenance" name="maintenance" tab url="/maintenance/" /> : "";
    return (<>
        <Views tabs className="safe-areas">
            <Toolbar tabbar labels bottom>
                <Link tabLink="#view-home" tabLinkActive iconMaterial="remove_red_eye" text={t(`Nav.dashboard`)} />
                {maintenanceTab}
                <Link tabLink="#view-settings" iconMaterial="more_horiz" iconBadge={notifications} badgeColor='orange'>
                    <span className="tabbar-label">{t(`Nav.settings`)}</span>
                </Link>
            </Toolbar>
            <Groups />

            <View id="view-home" main tab tabActive url="/" />
            {maintenanceView}
            <View id="view-settings" name="settings" tab url="/settings/" />
        </Views>
    </>)
}