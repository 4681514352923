import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';
import {
    f7,
    Button,
    Navbar,
    Page,
    Segmented,
    Subnavbar,
    Card,
    CardContent,
    Col,
    Row,
    Tab,
    Tabs,
    BlockTitle
} from 'framework7-react';
import { DateTime } from 'luxon';
import { getMachineDetails, selectMachineDetails } from 'app/features/machines';
import { RootState } from 'app/store';
import { getLanguage, useTranslation } from 'app/services/i18n';
import Picture from 'domain/components/Picture';
import styled from 'styled-components';
import DoubleGauge from 'domain/components/DoubleGauge';
import BarChart, { statusesToBarChart } from '../components/BarChart';
import Shift from 'domain/components/Shift';
import { config } from 'app/services/config';
import SemiGauge from 'domain/components/SemiGauge';
import { getGoalProgressVelocity, getInstantVars, instantData, kpiData, statusColor, strInstantVal, strKpiVal } from 'domain/helpers';
import { IMachineDetails, IShift } from 'domain/types';

interface RouteProps {
    id: string;
}

const Data = styled.div`
    font-size: 16.56px;
    color: #002E6E;
    font-family: 'SF Pro Display', sans-serif;
    font-weight: normal;
    > label {
        display: block;
    }
`;

const Gauges = styled.div`
    margin-top: 1em;
    text-align: center;
    .gauge {
        max-width: 80px;
    }
`;

export const KpiTag = styled.div`
    display: inline-block;
    padding: 2px 5px;
    font-size: 8.28px;
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    color: #fff;
    background-color: #30BEFE;
    text-transform: uppercase;
`;

const toTime = (dt: string) => {
    const [lang] = getLanguage().split('-');
    const formats = { it: 'HH:mm', en: 'hh:mm a' } as any;
    return DateTime.fromISO(dt).toFormat(formats[lang]);
}

export const RenderShift = (shift: IShift) => {
    const current = _get(shift, 'Progress.Current');
    const objective = _get(shift, 'Goal.Objective');
    const kpi = kpiData(_get(shift, 'Goal.Kpi'));
    const velocity = _get(shift, 'Velocity');
    if (!kpi) return null;

    // getGoalProgressVelocity(shift)/100
    return [
        <>{strKpiVal(current, objective, kpi)} <KpiTag>{kpi.tag}</KpiTag></>,
        <DoubleGauge inner={objective != 0 ? current / objective : 0} outer={velocity / 100} />
    ]
}

const Machine = (props: RouteProps) => {
    const id = config.mock ? "ckb23mf3s01ve0h6gkv5wsfnv" : props.id;
    const [tab, setTab] = useState('instant');
    const { t } = useTranslation();
    const lang = getLanguage();
    const item = useSelector(state => selectMachineDetails(state as RootState, id));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getMachineDetails({
            id,
            segments: ["shift", "shift_lasts", "history", "status", "cncproc_rapid", "cncproc_feed", "cncproc_rate", "sub_feed", "sub_speed", "gap", "program", "program_status", "operator", "prod_rate"],
            args: {
                history: {
                    from: DateTime.local().plus({ day: -30 }),
                    to: DateTime.local(),
                }
            }
        }));
    }, [dispatch, id]);

    const shift = _get(item, 'shift');
    const shifts = _get(item, 'shift_lasts');
    const status = _get(item, 'status');
    const history = _get(item, 'history');
    let operator = _get(item, 'operator.value');
    if (operator === 'null') operator = '-';

    const instants = getInstantVars(item);

    const rate = _get(item, 'cncproc_rate[0].value', null)
    const program = _get(item, 'program[0].value', null)
    const program_status = _get(item, 'program_status[0].value', null)
    const history_statuses = _get(history, 'statuses', {});
    const kpi = shift ? RenderShift(shift) : null;
    const isDisconnected = !item.Device;

    const barChartItems = useMemo(() => {
        return statusesToBarChart(history_statuses);
    }, [lang, history_statuses]);

    return (
        <Page name="machine" style={{ background: f7.device.ios ? "" : "#efeff4" }}>
            <Navbar title={`${item.Alias || item.Model_Name}`} subtitle={`${item.Machine_Serial}`} backLink backLinkForce>
            </Navbar>
            <Subnavbar>
                {!isDisconnected && <>
                    <Segmented raised style={{ margin: '50px' }}>
                        <Button color="white" textColor="black" tabLink="#instant" tabLinkActive style={{ textTransform: 'capitalize' }}>{t(`Machine.tab_instant`)}</Button>
                        <Button color="white" textColor="black" tabLink="#historical" style={{ textTransform: 'capitalize' }}>{t(`Machine.tab_historical`)}</Button>
                    </Segmented>
                </>}
            </Subnavbar>
            <Tabs>
                <Tab id="instant" tabActive onTabShow={() => { setTab('instant'); }}>
                    <Card>
                        <CardContent>
                            <Row>
                                <Col width="25">
                                    <Picture border={statusColor(_get(status, 'Status'))} item={item} />
                                </Col>
                                <Col width="75">
                                    {isDisconnected && <>
                                        <Data>
                                            <label className="goal-title">{t(`Machine.name`)}</label>
                                            {item.Alias || item.Model_Name}
                                        </Data>
                                        <Data>
                                            <label className="goal-title">{t(`Machine.serial`)}</label>
                                            {item.Machine_Serial}
                                        </Data>
                                    </>}
                                    {!isDisconnected && <>
                                        {shift && (
                                            <Data>
                                                <label className="goal-title">{t(`Machine.shift`)}</label>
                                                {t('Machine.shift_nr')} {shift.Number}: {toTime(shift.StartTime)} - {toTime(shift.EndTime)}
                                            </Data>
                                        )}
                                        {operator && (
                                            <Data>
                                                <label className="goal-title">{t(`Machine.operator`)}</label>
                                                {operator}
                                            </Data>
                                        )}
                                        {status && (
                                            <Data>
                                                <label className="goal-title">{t(`Machine.status`)}</label>
                                                {t(`Machine.status_${status.Status}`)}
                                            </Data>
                                        )}
                                    </>}
                                </Col>
                            </Row>
                        </CardContent>
                    </Card>

                    {!isDisconnected && <>
                        <Card>
                            <CardContent>
                                {program &&
                                    <Data>
                                        <label className="goal-title">{t(`Machine.program`)}</label>
                                        {program}
                                    </Data>
                                }
                                {rate &&
                                    <Data>
                                        <label className="goal-title">{t(`Machine.feedrate`)}</label>
                                        {rate}
                                    </Data>
                                }
                                {program_status && (
                                    <Data>
                                        <label className="goal-title">{t(`Machine.execution`)}</label>
                                        {t(`Machine.status_${program_status}`)}
                                    </Data>
                                )}
                                <Gauges>
                                    <Row>
                                        {Object.keys(instants).map((field) => {
                                            const value = instants[field];
                                            const data = instantData(field);
                                            return <Col width="50">
                                                <SemiGauge
                                                    value={value}
                                                    strValue={strInstantVal(field, value)}
                                                    label={`${t(`Machine.${field}`).toUpperCase()}<br />(${data.unit})`}
                                                    needle={data.noNeedle !== true}
                                                    max={data.max}
                                                />
                                            </Col>;
                                        })}
                                    </Row>
                                </Gauges>
                            </CardContent>
                        </Card>
                    </>}

                    {kpi && (
                        <Card>
                            <CardContent>
                                <Row>
                                    <Col width="50">
                                        <Data>
                                            <label className="goal-title">{t('Machine.goal')}</label>
                                            {kpi[0]}
                                        </Data>
                                    </Col>
                                    <Col width="50">
                                        {kpi[1]}
                                    </Col>
                                </Row>
                            </CardContent>
                        </Card>
                    )}
                </Tab>
                <Tab id="historical" onTabShow={() => { setTab('historical'); }} >
                    <BlockTitle className="historical-title">{t('Machine.timeline_title')}</BlockTitle>
                    <Card>
                        {/* <CardHeader className="no-border">{t('Machine.timeline_title')}</CardHeader> */}
                        <CardContent>
                            {tab === 'historical' && (
                                <BarChart
                                    height='100px'
                                    items={barChartItems}
                                />
                            )}
                        </CardContent>
                    </Card>

                    {shifts && shifts.length && (<>
                        <BlockTitle className="historical-title">{t('Machine.shifts_title')}</BlockTitle>
                        {shifts.map((shift, k) => <Shift key={k} item={shift} />)}
                    </>)}
                </Tab>
            </Tabs>
        </Page>
    )
}

export default Machine;