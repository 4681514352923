import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    f7,
    Button,
    Card,
    List,
    ListGroup,
    ListItem,
    Navbar,
    NavLeft,
    NavRight,
    Page,
    Segmented,
    Subnavbar,
    Toggle,
    Icon,
    Link,
} from 'framework7-react';
import { selectMachines, getMachines, getMachineDetails, selectMachinesDetails, selectActiveMachineGroup, selectLoading, selectEnd } from 'app/features/machines';
import { useTranslation } from 'app/services/i18n';
import Machine from 'domain/components/Machine';
import { DateTime } from 'luxon';

const Dashboard = () => {
    const { t } = useTranslation();
    const [timeline, setTimeline] = useState(false);
    const loading = useSelector(selectLoading);
    const end = useSelector(selectEnd);
    const group = useSelector(selectActiveMachineGroup);
    const items = useSelector(selectMachines);
    const details = useSelector(selectMachinesDetails);
    const dispatch = useDispatch();

    const loadMore = useCallback(() => {
        if (group && !loading.index && !end) {
            dispatch(getMachines({
                group,
                limit: 5,
                skip: items.length,
            }));
        }
    }, [dispatch, group, items.length, loading.index, end]);

    useEffect(() => {
        if (group) {
            loadMore();
        }
    }, [group]);

    useEffect(() => {
        /**
         * chiedere chiarimenti a mauro su questi segments
         */
        items.forEach(item => {
            dispatch(getMachineDetails({
                id: item.Id,
                segments: timeline ? ["shift", "history", "status", "cncproc_rapid", "cncproc_feed", "sub_feed", "sub_speed", "gap", "operator", "prod_rate"] : ["shift", "status", "operator"],
                args: {
                    history: {
                        from: DateTime.local().plus({ week: -1 }),
                        to: DateTime.local(),
                    }
                }
            }));
        });
    }, [dispatch, items, timeline]);

    const $ = items.reduce((acc, item) => {
        if (!acc[item.Id]) acc[item.Id] = 0;
        acc[item.Id]++;
        return acc;
    }, {} as any);


    const filterOnline = (machine: { Device: any; }) => {
        return machine.Device;
    }
    const filterOffline = (machine: { Device: any; }) => {
        return !machine.Device;
    }

    const onlineMachines = items.filter(filterOnline);
    const offlineMachines = items.filter(filterOffline);

    const sortedMachines = onlineMachines.concat(offlineMachines);

    return (
        <Page
            name="dashboard"
            infinite
            infiniteDistance={50}
            infinitePreloader={loading.index}
            onInfinite={loadMore}>

            <Navbar className="main-section-title">
                <NavLeft className="main-section-title">
                    {t(`Nav.dashboard_title`)}
                </NavLeft>
                <NavRight>
                    <Button
                        id='group-button'
                        popupOpen=".demo-popup"
                        style={{ textTransform: 'capitalize' }}>
                        {t(`Nav.groups`)}
                    </Button>
                </NavRight>
            </Navbar>

            <Subnavbar inner={false} style={{ justifyContent: 'flex-start', padding: '16px' }}>
                <Toggle onToggleChange={(val) => setTimeline(val)} />
                <span className="status-timeline">
                    {t(`Dashboard.status_timeline`)}
                </span>
            </Subnavbar>

            <List mediaList inset style={{ marginTop: '0px' }}>
                {sortedMachines.map((item) => {
                    const isDisconnected = item ? !item.Device : false;

                    return <List className={f7.device.ios ? "" : "elevation-6 elevation-hover-24 elevation-pressed-12 elevation-transition"}
                        style={{ margin: '10px 0px', borderRadius: f7.device.ios ? 'inherit' : '11px' }}>
                        <ListItem
                            className='machine'
                            key={item.Id}
                            link={isDisconnected ? undefined : `/machines/${item.Id}/`}
                        // title={item.Alias || item.Model_Name}
                        // subtitle={item.Machine_Serial}
                        >
                            <Segmented style={{ justifyContent: 'space-between' }}>
                                <Segmented>
                                    <div className="item-title">{item.Alias || item.Model_Name}</div>
                                    <div className="item-subtitle">{item.Machine_Serial}</div>
                                </Segmented>
                                <Link iconMaterial="chevron_right" style={{ color: '#707070' }} />
                            </Segmented>
                            <Machine
                                key={item.Id}
                                item={item}
                                details={details[item.Id]}
                                timeline={timeline}
                                disconnected={isDisconnected}
                            />
                        </ListItem>
                    </List>
                })}
            </List>
        </Page>
    )
}

export default Dashboard;